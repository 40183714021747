import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Collapse, Flex, Space } from 'antd';
import { IconApi } from '@tabler/icons-react';

import Document from '../Document';
import router from '~/configs/routes';

const responseGetSuccess = {
    data: [
        {
            price: 30,
            id: 39823326,
            title: 'API Check Login Free Fire',
            status: 'activated',
            version: '1.0.0',
            category: 'FreeFire_login',
            slug_url: 'api-check-login-freefire',
            priority: 1,
            old_price: 100,
            image_url: 'https://cdn-gop.garenanow.com/gop/app/0000/100/067/icon.png',
            free_usage: 10,
            description:
                'API giúp bạn có thể kiểm tra thông tin về tải khoản game Free Fire mà không cần đăng nhập bằng cách gửi ID người chơi cho chúng tôi, chúng tôi sẽ trả cho bạn kết quả cho bạn.',
            document_text: '#### Document trên github [tại đây](https://github.com/mavietha7z/docs_apis_thegioicode/tree/main)',
            document_html:
                '<h4>Document trên github <a href="https://github.com/mavietha7z/docs_apis_thegioicode/tree/main">tại đây</a></h4>\n',
            apikey: {
                key: 'SV-e2247a38-8257-473b-9023-005030b11111',
                used: 0,
                status: true,
                webhooks: {
                    url: [],
                    security_key: '',
                },
                free_usage: 5,
                service_type: 'Api',
            },
        },
        '...',
    ],
    status: 200,
    message: 'Lấy danh sách public api thành công',
};

const responseDetailSuccess = {
    data: {
        id: 39823326,
        title: 'API Check Login Free Fire',
        status: 'activated',
        version: '1.0.0',
        category: 'FreeFire_login',
        slug_url: 'api-check-login-freefire',
        priority: 1,
        old_price: 100,
        image_url: 'https://cdn-gop.garenanow.com/gop/app/0000/100/067/icon.png',
        free_usage: 10,
        description:
            'API giúp bạn có thể kiểm tra thông tin về tải khoản game Free Fire mà không cần đăng nhập bằng cách gửi ID người chơi cho chúng tôi, chúng tôi sẽ trả cho bạn kết quả cho bạn.',
        document_text: '#### Document trên github [tại đây](https://github.com/mavietha7z/docs_apis_thegioicode/tree/main)',
        document_html:
            '<h4>Document trên github <a href="https://github.com/mavietha7z/docs_apis_thegioicode/tree/main">tại đây</a></h4>\n',
        price: 30,
        apikey: {
            key: 'SV-e2247a38-8257-473b-9023-005030b11111',
            used: 0,
            status: true,
            webhooks: {
                url: [],
                security_key: '',
            },
            free_usage: 5,
            service_type: 'Api',
        },
    },
    status: 200,
    message: 'Lấy thông tin chi tiết public api thành công',
};

function PublicAPI() {
    return (
        <Fragment>
            <Helmet>
                <title>Thegioicode.com - Document Public API</title>
                <meta
                    key="description"
                    name="description"
                    content="API document này giúp lấy thông tin về public api của chúng tôi giúp bạn tích hợp dễ dành hơn"
                />

                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`https://thegioicode.com${router.document_public_api}`} />
                <meta property="og:url" content={`https://thegioicode.com${router.document_public_api}`} />
                <meta property="og:title" content="Thegioicode.com - Document Public API" />
                <meta property="og:image" content="https://thegioicode.com/images/izgdwacbGc.png" />
                <meta
                    property="og:description"
                    content="API document này giúp lấy thông tin về public api của chúng tôi giúp bạn tích hợp dễ dành hơn"
                />
            </Helmet>

            <Document
                keyTab="3"
                label={
                    <span className="box-align-center gap-2 text-subtitle">
                        <IconApi width={20} height={20} />
                        Public API
                    </span>
                }
            >
                <div className="api-detail">
                    <Space direction="vertical" className="w-full">
                        <Collapse
                            accordion
                            items={[
                                {
                                    key: '1',
                                    label: <div className="font-bold text-hover">Lấy danh sách Public API</div>,
                                    children: (
                                        <div>
                                            <Flex align="center">
                                                <span className="document-method text-success font-bold">GET</span>
                                                <span className="document-method flex-1 text-start">
                                                    https://thegioicode.com/api/v2/public-api
                                                </span>
                                            </Flex>

                                            <div className="mt-3 mb-1">Thành công:</div>
                                            <div>
                                                <div className="copy">
                                                    <pre>
                                                        <code className="text-copy success">
                                                            {JSON.stringify(responseGetSuccess, null, 4)}
                                                        </code>
                                                    </pre>
                                                </div>
                                            </div>

                                            <div className="mt-3 mb-1">Thất bại:</div>
                                            <div>
                                                <div className="copy">
                                                    <pre>
                                                        <code className="text-copy">
                                                            {JSON.stringify(
                                                                { status: 'Mã code lỗi', error: 'Thông tin lỗi trả về' },
                                                                null,
                                                                4,
                                                            )}
                                                        </code>
                                                    </pre>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />

                        <Collapse
                            accordion
                            items={[
                                {
                                    key: '1',
                                    label: <div className="font-bold text-hover">Thông tin chi tiết Public API</div>,
                                    children: (
                                        <div>
                                            <Flex align="center">
                                                <span className="document-method text-success font-bold">GET</span>
                                                <span className="document-method flex-1 text-start">
                                                    https://thegioicode.com/api/v2/public-api/:api_id
                                                </span>
                                            </Flex>

                                            <div className="mt-3 mb-1">Thành công:</div>
                                            <div>
                                                <div className="copy">
                                                    <pre>
                                                        <code className="text-copy success">
                                                            {JSON.stringify(responseDetailSuccess, null, 4)}
                                                        </code>
                                                    </pre>
                                                </div>
                                            </div>

                                            <div className="mt-3 mb-1">Thất bại:</div>
                                            <div>
                                                <div className="copy">
                                                    <pre>
                                                        <code className="text-copy">
                                                            {JSON.stringify(
                                                                { status: 'Mã code lỗi', error: 'Thông tin lỗi trả về' },
                                                                null,
                                                                4,
                                                            )}
                                                        </code>
                                                    </pre>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </Space>
                </div>
            </Document>
        </Fragment>
    );
}

export default PublicAPI;
