import { Card, Empty } from 'antd';

const data = [];

function Posts() {
    return (
        <Card
            className="rounded-15 mb-4"
            title={
                <h2 className="font-semibold mb-0 white-space-break">
                    <span className="font-size-18 font-semibold">Bài viết</span>
                </h2>
            }
        >
            {data.length > 0 ? (
                data.map((item) => (
                    <div className="link-color" key={item.id}>
                        <a href={item.link} className="text-primary font-bold font-size-15" target="_blank" rel="noreferrer">
                            {item.title}
                        </a>
                        <p className="text-subtitle line-height-17 mt-2px mb-4">{item.description}</p>
                    </div>
                ))
            ) : (
                <Empty description="Chưa có bài viết nào" />
            )}
        </Card>
    );
}

export default Posts;
